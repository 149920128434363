import { React, useEffect } from 'react';
import { Route, Routes, Navigate} from 'react-router-dom';
import { AbsencesContext } from '../contexts/AbsencesContext';
import { CalendarContext } from '../contexts/CalendarContext';
import { UsersnapProvider } from '../contexts/UsersnapContext';
import { datadogLogs } from '@datadog/browser-logs';
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { msalInstance } from '../config/MsalConfig';
import { Container } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import "../translations/i18n";

import SignIn from '../pages/SignIn/SignIn';
import Home from '../pages/Home/Home';
import RequestForm from '../pages/RequestForm/RequestForm';
import Notifications from '../pages/Notifications/Notifications';
import FAQ from '../pages/FAQ/FAQ';
import YearCalendar from '../pages/YearCalendar/YearCalendar';

import Header from './Header';
import MainMenu from './MainMenu';
import TeamsConsentPopup from "./TeamsConsentPopup";
import TeamsClosePopup from "./TeamsClosePopup";

import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/styles.scss';

// Datadog handler
datadogLogs.init({
  version: '0.1',
  clientToken: `${process.env.REACT_APP_DATADOG_TOKEN}`,
  site: 'datadoghq.eu',
  service: 'labora-employee-app-prod',
  forwardErrorsToLogs: true,
  sessionSampleRate: 100
});
export function logToDatadog(Message, StatusType = 'info') {
  // Possible StatusType: "error", "info", "debug", "warn"
  datadogLogs.logger.log(Message, { /* Additional context */ }, StatusType);
}

const isDev = `${process.env.REACT_APP_IS_DEV}`;

// Main App
function App() {
  return (
    <MsalProvider instance={msalInstance}>
      {isDev === "dev" &&
        <nav style={{ backgroundColor: '#cc1e1e', position: 'fixed', top: '0', width: '100%', zIndex: '1000', borderBottom: '1px solid #5f5e97', color: 'yellow', textAlign: 'center', fontSize: '15px' }}>
            Staging or Test instance
        </nav>
      }
      <div className="app d-flex flex-column align-items-center min-vh-100 h-100 p-0 position-relative">
        <AuthenticatedTemplate> <PagesAuthenticated /> </AuthenticatedTemplate>
        <UnauthenticatedTemplate> <PagesUnauthenticated /> </UnauthenticatedTemplate>
      </div>
      <UsersnapProvider />
    </MsalProvider >
  )
}

const Logout = () => {
  useEffect(() => {
    try {
      // Attempt logout using logoutRedirect
      msalInstance.logoutRedirect({
        account: msalInstance.getActiveAccount(),
        redirectUri: window.location.origin
      });
      logToDatadog("LogOut: User Logout via logoutRedirect method");
    } catch (logoutRedirectError) {
      logToDatadog(`LogOut: Error method logoutRedirect: ${logoutRedirectError.message}`, "error");
      try {
        // If logoutRedirect fails, try logoutPopup
        msalInstance.logoutPopup({ account: msalInstance.getActiveAccount() });
        logToDatadog("LogOut: User Logout via logoutPopup method");
      } catch (logoutPopupError) {
        logToDatadog(`LogOut: Error method logoutPopup: ${logoutPopupError.message}`, "error");
      }
    }
    localStorage.clear();
  },[]);
};


const PagesAuthenticated = () => {
  const { t } = useTranslation(['translation', 'faq']);

  return (
    <AbsencesContext>
      <Header t={t} />
      <RequestForm />
      <Container className="main-container d-flex justify-content-between w-100 p-0">
        <div className="d-none d-xl-flex"><MainMenu toggleOverlay={() => { return false }} /></div>
        <Routes>
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="/" element={<Home t={t} />} />
          <Route path="/year-calendar" element={<CalendarContext><YearCalendar t={t} /></CalendarContext>} />
          <Route path="/notifications" element={<Notifications t={t} />} />
          <Route path="/faq" element={<FAQ t={t} />} />
          <Route path="/logout" element={<Logout />} />;
        </Routes>
      </Container>
    </AbsencesContext>
  )
}

const PagesUnauthenticated = () => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/" replace />} />
      <Route path="/" element={<SignIn />} />
      <Route path="/auth-consent" element={<TeamsConsentPopup />} />
      <Route path="/auth-start" element={<TeamsClosePopup />} />
    </Routes>
  )
}

export default App;
