import React, { useState, useMemo } from 'react';
import { useAbsenceAPI } from '../../contexts/AbsencesContext';
import { CalendarContext } from '../../contexts/CalendarContext';
import { Link, useNavigate} from 'react-router-dom';
import { Alert, Spinner, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { CircleProgress } from 'react-progress-component';
import moment from 'moment';
import "moment/locale/de";
import "moment/locale/en-gb";
import "./Home.scss"

import MonthCalendar from '../../components/MonthCalendar';
import AbsenceCard from '../../components/AbsenceCard';
import { logToDatadog } from '../../components/App';

const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

const Home = ({ t }) => {
    const { absencesSelectedMonth, absencesSelectedYear, statsCurrentYear, setStateRequestForm, activeDate, setActiveDate, errorAPI, successMessageRequest, isLoadingAPI, getAbsenceDetails, LeaveRequestsOfSelectedMonth, getDaysBetweenDateAndToday } = useAbsenceAPI();
    const [isCalendarVisibleOnMobile, setIsCalendarVisibleOnMobile] = useState(false);
    const [viewAllAbsencesOfCurrentYear, setViewAllAbsences] = useState(false);
    const isActiveYear = activeDate.isSame(moment(), 'year');
    const displayNextAbsences = isActiveYear && !viewAllAbsencesOfCurrentYear;
    const navigate = useNavigate();
    const isMobileHome = isMobileDevice();
    let displayedAbsencesCount = 0;
    let pastAbsencesCount = 0;

    const activeYear = activeDate.year();

    const absenceCardsForSelectedYear = useMemo(() => {
        let index = 0;
        const cards = [];

        for (const absence of absencesSelectedYear.slice().reverse()) {
            // Don't show public holidays in cards 
            if (absence.time_off_main_category !== "public_holiday") {
                const isBeforeToday = moment().startOf('day').isSameOrBefore(moment(absence.date_to).startOf('day'));
                const isWithinSevenDaysFromToday = getDaysBetweenDateAndToday(absence.date_from) >= -7;
    
                if (!displayNextAbsences || (displayNextAbsences && index < 3 && (isWithinSevenDaysFromToday || isBeforeToday))) {
                    cards.push(
                        <AbsenceCard key={absence.id} request={absence} size={isCalendarVisibleOnMobile ? 'small' : 'large'} />
                    );
                    index++;
                    displayedAbsencesCount++;
                } else if (!isWithinSevenDaysFromToday) {
                    pastAbsencesCount++;
                }
            }
        }

        return cards;
    }, [absencesSelectedYear, displayNextAbsences, displayedAbsencesCount, getDaysBetweenDateAndToday, isCalendarVisibleOnMobile, pastAbsencesCount])

    const toggleViewOnMobile = () => {
        let newValue = !isCalendarVisibleOnMobile;
        setIsCalendarVisibleOnMobile(newValue);
        document.querySelector("#toggle-" + isCalendarVisibleOnMobile).classList.remove("active");
        document.querySelector("#toggle-" + !isCalendarVisibleOnMobile).classList.add("active");
        if (newValue) {
            document.querySelector("#toggle-calendar-view").classList.remove("hidden", "d-none");
            document.querySelector("#toggle-requests").classList.add("hidden", "d-none");
        } else {
            document.querySelector("#toggle-calendar-view").classList.add("hidden", "d-none");
            document.querySelector("#toggle-requests").classList.remove("hidden", "d-none");
        }
    }

    const handleToggleRequests = () => {
        setViewAllAbsences(!viewAllAbsencesOfCurrentYear);
        setTimeout(() => {
            if (window.innerHeight > 1100) {
                window.scrollTo({ top: 206 * pastAbsencesCount, behavior: 'instant' })
            } else {
                window.scrollBy({ top: 206 * pastAbsencesCount, behavior: "instant" })
            }
        }, 0);
    }

    const openCreateRequestForm = () => {
        getAbsenceDetails(undefined);
        setStateRequestForm({ display: true, view: "create", id: undefined, markDays: absencesSelectedYear })
    }

    const AbsenceStat = ({ value, maxValue, color, label, tooltipText }) => {
        if (maxValue <= 0){
            // maxValue can't be less or equal zero.
            maxValue=0.1;
        };
        return (
            <OverlayTrigger placement={"top"} overlay={<Tooltip show={tooltipText}>{tooltipText}</Tooltip>}>
                <div className="single-stat-container">
                    <div className="single-stat">
                        <CircleProgress progressValue={value} totalProgress={maxValue} radius={window.innerWidth > 1200 ? 30 : 20} strokeColor={color} strokeBackgroundColor={"rgba(59, 69, 89, 0.1)"} strokeWidth={2} progressLabel={''} />
                    </div>
                    {label}
                </div>
            </OverlayTrigger>
        )
    }
    const handleRefresh = () => {
        logToDatadog("missing_accessToken: User clicked handleRefresh button on main page.");
        window.location.reload();
    }
    

    return (
        <div className="home page-container w-100 position-relative">
            {errorAPI && <div className="global-message start-0">
            {errorAPI === 'missing_accessToken' ? 
                <Alert variant="info" className="api-error px-4" >
                    <h5><b>Ops!</b></h5>
                    <br />
                    {t("Alert.refresh_page")}
                    <br />
                    <button className="btn-secondary" onClick={handleRefresh} style={{height: 30}}>
                        <span>Refresh</span>
                    </button>
                    <br />

                    <span>{t("Alert.problem_repeats")}</span>
                    <Link to="/logout" className="main-menu__list-item">
                        <img src={process.env.PUBLIC_URL + "/images/icon-logout.svg"} alt="" width={18} height={18} />
                        <div className="main-menu__list-item-text main-menu__list-item-text--red">{t("MainMenu.logout")}</div>
                    </Link>
                </Alert>
                :
                <Alert variant="danger" className="api-error px-4">{t("Alert.critical_error")}<br /><b>{errorAPI}</b></Alert>
            }
            </div>}
            <div id="view-requests" className="d-flex flex-column align-items-center view">
                <div className="page-container__divider d-xl-none" />
                {successMessageRequest && <Alert variant="success" className="d-xl-none text-center w-100 m-0"><b>{successMessageRequest}</b></Alert>}
                <div className="topbar d-flex d-xl-none justify-content-between flex-xl-row-reverse">
                    <div role="group" className="btn-group" defaultValue="false" onClick={toggleViewOnMobile}>
                        <input className="btn-check" name="toggleView" type="radio" autoComplete="off" value="false" />
                        <img className="btn-toggle p-0 active" id="toggle-false" src={process.env.PUBLIC_URL + "/images/icon-list.svg"} alt="" />
                        <input className="btn-check" name="toggleView" type="radio" autoComplete="off" value="true" />
                        <img className="btn-toggle" id="toggle-true" src={process.env.PUBLIC_URL + "/images/icon-calendar.svg"} alt="" />
                    </div>
                    {!isCalendarVisibleOnMobile && (
                        <div className="home__select_year select-year d-xl-none">
                            {activeYear > moment().subtract(1, "years").year() && (
                                <button className="select-year__button--active" onClick={() => setActiveDate(moment(activeDate).subtract(1, "years"))}>
                                    <img src={process.env.PUBLIC_URL + "/images/icon-btn-back.svg"} alt="" />
                                </button>
                            )}
                            {activeYear}
                            {activeYear < moment().add(1, "years").year() && (
                                <button className="select-year__button--active" onClick={() => setActiveDate(moment(activeDate).add(1, "years"))}>
                                    <img src={process.env.PUBLIC_URL + "/images/icon-btn-back.svg"} alt="" className="mirrored" />
                                </button>
                            )}
                        </div>
                    )}
                </div>
                <div className="feed d-flex flex-column flex-xl-row-reverse justify-content-center w-100">
                    <div id="toggle-calendar-view" className="d-none d-xl-flex hidden">
                        <CalendarContext>
                            <MonthCalendar />
                        </CalendarContext>
                        <button className="btn-secondary" onClick={() => navigate("/year-calendar")}>{t("Home.year_overview")}</button>
                        <div className="absence-cards-month-wrapper d-xl-none">
                            <div className="requests-heading">{t("Home.absences")} {moment(activeDate).format('MMMM YYYY')}</div>
                            {absencesSelectedMonth.length === 0 && <Alert className="w-100">{`${t("RequestList.no_absences_available")} in ${moment(activeDate).format('MMMM YYYY')}`}</Alert>}
                            <LeaveRequestsOfSelectedMonth />
                        </div>
                    </div>
                    <div id="toggle-requests" className="box large align-items-center w-100">
                        {successMessageRequest && <Alert variant="success" className="d-none d-xl-flex text-center w-100 m-0"><b>{successMessageRequest}</b></Alert>}
                        <div className="box-absence-stats default-box position-relative text-center">
                            <div className="welcome-box">
                                <div className="box-absence-stats__date">{moment().format("dddd, DD. MMMM Y")}</div>
                                <div className="box-absence-stats__welcome">{t("Home.welcome")}</div>
                            </div>
                            <div className="box-absence-stats__stats_summary">
                                <div> <b>{activeYear}</b></div>
                                <div> {t("RequestList.stats_summary")} </div>
                            </div>
                            {Object.keys(statsCurrentYear).length > 0 &&
                            <div className="stats-container">
                                <AbsenceStat value={statsCurrentYear.available} maxValue={statsCurrentYear.max_leaves} color={"#3FB6EB"} label={t("Stats.available")} tooltipText={t("TooltipStats.available")} />
                                <AbsenceStat value={statsCurrentYear.used} maxValue={statsCurrentYear.max_leaves} color={"#3FB6EB"} label={t("Stats.used")} tooltipText={t("TooltipStats.used")} />
                                <AbsenceStat value={statsCurrentYear.pending} maxValue={statsCurrentYear.max_leaves} color={"#3FB6EB"} label={t("Stats.pending")} tooltipText={t("TooltipStats.pending")} />
                                {!isMobileHome && statsCurrentYear.remaining_previous_year > 0 && <AbsenceStat value={statsCurrentYear.remaining_previous_year} maxValue={statsCurrentYear.remaining_previous_year} color={"#8C9DAE"} label={t("Stats.remaining_previous_year")} tooltipText={t("TooltipStats.remaining_previous_year")} />}
                                {!isMobileHome && statsCurrentYear.sick_days > 0 && <AbsenceStat value={statsCurrentYear.sick_days} maxValue={statsCurrentYear.sick_days} color={"#FCB31A"} label={t("Stats.sick_days")} tooltipText={t("TooltipStats.sick_days")} />}
                            </div>}
                            {isMobileHome && Object.keys(statsCurrentYear).length > 0 &&
                            <div className="stats-container-mobile">
                                {isMobileHome && statsCurrentYear.remaining_previous_year > 0 && <AbsenceStat value={statsCurrentYear.remaining_previous_year} maxValue={statsCurrentYear.remaining_previous_year} color={"#8C9DAE"} label={t("Stats.remaining_previous_year")} tooltipText={t("TooltipStats.remaining_previous_year")} />}
                                {isMobileHome && statsCurrentYear.sick_days > 0 && <AbsenceStat value={statsCurrentYear.sick_days} maxValue={statsCurrentYear.sick_days} color={"#FCB31A"} label={t("Stats.sick_days")} tooltipText={t("TooltipStats.sick_days")} />}
                            </div>
                            }
                            {!isLoadingAPI && <button className="btn-request--main btn-request d-none d-xl-block" onClick={openCreateRequestForm}>{t("Button.request")}</button>}
                        </div>
                        {isLoadingAPI ? <Spinner className="m-4" size="md" />
                            : <>
                                <div className={`requests-heading fw-normal`}>{displayedAbsencesCount > 0 && displayNextAbsences ? t("RequestList.next_absences") : `${t("RequestList.all_absences")} ${activeYear}`}</div>
                                {displayedAbsencesCount === 0 && <Alert id="show_no_absences_available" className="text-center w-100 m-0">
                                    {absencesSelectedYear.length > 0 && displayNextAbsences ? `${t("RequestList.no_absences_planned_in_near_future")}` : `${t("RequestList.no_absences_available")} in ${activeYear}`}
                                </Alert>}
                                {absenceCardsForSelectedYear}
                                {((viewAllAbsencesOfCurrentYear && isActiveYear) || (displayedAbsencesCount < absencesSelectedYear.length && displayNextAbsences)) &&
                                    <button className="btn-secondary" id="show-past-absences" onClick={handleToggleRequests}>{viewAllAbsencesOfCurrentYear ? t("RequestList.collapse") : t("RequestList.view_all")}</button>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
            <div className="footer-button-mobile d-xl-none">
                <button className="btn-request--mobile btn-request" onClick={openCreateRequestForm}>{t("Button.request")}</button>
            </div>
        </div>
    )
}

export default Home