import React, { useState, useEffect, useCallback } from 'react';
import { useAbsenceAPI } from '../../contexts/AbsencesContext';
import { useTranslation } from 'react-i18next';
import { Form, Button, Spinner, Alert, Modal } from 'react-bootstrap';
import { RangeDatePicker, SingleDatePicker } from 'react-google-flight-datepicker';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "react-google-flight-datepicker/dist/main.css";
import Select, { components as SelectComponents } from "react-select";
import i18next from 'i18next';
import moment from "moment";
import "moment/locale/de";
import "moment/locale/en-gb";
import "./RequestForm.scss"
import { logToDatadog } from '../../components/App';


const inputSelectWithoutKeyBoardPopUp = (props) => (
  <SelectComponents.Input {...props} readOnly />
);
const InputDatepickerWithoutKeyboardPopUp = React.forwardRef((props, ref) => (
  <input {...props} readOnly ref={ref} />
));

const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}


const RequestForm = () => {
  const { t, i18n } = useTranslation();
  const { absenceDetails, absenceCategories, stateRequestForm, setStateRequestForm, statsCurrentYear, formErrorMsg, setFormErrorMsg, isLoadingRequest, setIsLoadingRequest, getAbsenceDetails, postAbsenceRequest, updateHolidayRequest, updateSickLeave, refuseHolidayRequest, deleteHolidayRequest, updateMarkedDaysInDatePickerDesktop, getDaysBetweenDateAndToday } = useAbsenceAPI();
  const [timeOffCategory, setTimeOffCategory] = useState("");
  const [mainCategory, setMainCategory] = useState("");
  const [requestState, setRequestState] = useState(undefined);
  const [highlightWithRanges, setHighlightWithRanges] = useState([]);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [isHalfDay, setIsHalfDay] = useState(false);
  const [dateFromPeriod, setDateFromPeriod] = useState(undefined);
  const [electronicCertificate, setElectronicCertificate] = useState(false);
  const [firstDayOfIncapacity, setFirstDayOfIncapacity] = useState(undefined);
  const [paperSickNote, setPaperSickNote] = useState(undefined);
  const [comment, setComment] = useState("");

  const [formError, setFormError] = useState(undefined);
  const [formNote, setFormNote] = useState(undefined);
  const [showModalRefuse, setShowModalRefuse] = useState(false);
  const [valuesSelectedCategory, setValuesSelectedCategory] = useState(undefined);
  const isMobileForm = isMobileDevice();
  const view = stateRequestForm.view;

  const closeDatePicker = () => {
    absenceDetails && absenceDetails.time_off_main_category === "sick_leave" && document.querySelector(".submit-button").click();
  }

  const updateDateRange = useCallback((startDate, endDate) => {
    setDateFrom(startDate);
    setDateTo(endDate);
 
    const startDateElement = document.querySelector("#start-date-input-button .selected-date");
    const endDateElement = document.querySelector("#end-date-input-button .selected-date");
    if (startDate && startDateElement?.textContent) {
      startDateElement.textContent = moment(startDate).format("dddd, DD. MMMM");
    }

    if (endDate && endDateElement?.textContent) {
      endDateElement.textContent = moment(endDate).format("dddd, DD. MMMM");
    }
  }, []);

  const updateDateRangeOnReadAbsence = useCallback((startDate, endDate, isRequestUnitHalf) => {
    setDateFrom(startDate);
    setDateTo(endDate);
    setTimeout(() => {
      const startDateElement = document.querySelector("#start-date-input-button .selected-date");
      const endDateElement = document.querySelector("#end-date-input-button .selected-date");
      const isHalfDayElement = document.querySelector("#switch_half_day");
      if (startDate && startDateElement?.textContent) {
        startDateElement.textContent = moment(startDate).format("dddd, DD. MMMM");
      }

      if (endDate && endDateElement?.textContent) {
        endDateElement.textContent = moment(endDate).format("dddd, DD. MMMM");
      }

      if (isHalfDayElement?.checked && !isRequestUnitHalf){
        setIsHalfDay(false);
      }
    }, 400);
  },
 []);

  useEffect(() => {
    if (formErrorMsg || (absenceDetails && stateRequestForm && stateRequestForm.id !== absenceDetails.id)) {
      setFormError(formErrorMsg);
      return;
    }
    moment.locale(i18n.language);
    let startDate = stateRequestForm?.view === "create" ? stateRequestForm?.startDate : absenceDetails?.date_from ?? null;
    let endDate = absenceDetails?.date_to ?? null;
    let isRequestUnitHalf = stateRequestForm?.view === "create" ? false : absenceDetails?.request_unit_half ?? null;
    // read option is set, when opening RequestForm on existing absence, we need to use delay to set DateRange based on received data from api.
    // create option is set, when opening RequestForm from `Request` button or on the calendar directly.
    // Missing stateRequestForm view means, reset fields.
    if (stateRequestForm?.view === "read" || stateRequestForm?.view === "create"){
      updateDateRangeOnReadAbsence(startDate, endDate, isRequestUnitHalf);
    } else {
      updateDateRange(startDate, endDate);
    }
    // Set Highlight days in date picker for Mobile Form version. 
    if (stateRequestForm?.view === "create" && isMobileForm && stateRequestForm?.markDays) {
      updateHighlightWithRanges(stateRequestForm?.markDays)
    }
    setTimeOffCategory(absenceDetails?.time_off_category ?? '');
    setMainCategory(absenceDetails?.time_off_main_category ?? '');
    setRequestState(absenceDetails?.state);
    setComment(absenceDetails?.name ?? '');
    setDateFromPeriod(absenceDetails?.request_date_from_period);
    // SickNotes
    setElectronicCertificate(absenceDetails?.electronic_certificate || absenceDetails?.sick_note_type === 'electronic_sick_note');
    if (stateRequestForm?.view === "edit") setPaperSickNote(undefined);
    setFirstDayOfIncapacity(absenceDetails?.incapacity_start_date);
    
    const startDateInputButton = document.querySelector('#start-date-input-button');
    if (startDateInputButton){
      startDateInputButton.style.pointerEvents = absenceDetails?.time_off_main_category === 'sick_leave' ? 'none' : 'auto';
    }
  }, [absenceDetails, formErrorMsg, getAbsenceDetails, i18n.language, stateRequestForm, updateDateRange]);

  useEffect(() => {
    setValuesSelectedCategory(absenceCategories.find(el => el.code === timeOffCategory));
    updateMarkedDaysInDatePickerDesktop();
  }, [absenceCategories, timeOffCategory, updateMarkedDaysInDatePickerDesktop, valuesSelectedCategory, isHalfDay])

  const submitForm = e => {
    e.preventDefault();
    setIsLoadingRequest(true);
    if (timeOffCategory && dateFrom && dateTo) {
      postAbsenceRequest(e, mainCategory, timeOffCategory, dateFrom, dateTo, isHalfDay, dateFromPeriod, electronicCertificate, firstDayOfIncapacity, paperSickNote, comment);
      if (paperSickNote){
        // After successfully post absence request, remove file from input field.
        setPaperSickNote(undefined);
      }
    } else {
      setFormError(t("Alert.error_select_required_fields"));
    }
  }

  const updateRequest = (e, time_off_main_category) => {
    e.preventDefault();
    setIsLoadingRequest(true);
    if (time_off_main_category === "time_off") {
      updateHolidayRequest(e, absenceDetails.id, dateFrom, dateTo, isHalfDay, dateFromPeriod, comment);
    } else if (time_off_main_category === "sick_leave") {
      updateSickLeave(e, absenceDetails.id, dateTo, electronicCertificate, firstDayOfIncapacity, paperSickNote, comment);
    }
  }

  const getCategories = () => {
    const groupedOptions = [
      { label: t("Category.time_off"), options: [] },
      { label: t("Category.sick_leave"), options: [] }
    ];

    absenceCategories
      .filter(category => category.main_category === "time_off" || category.main_category === "sick_leave")
      .sort((a, b) => a.id - b.id)
      .forEach(category => {
        const option = { id: category.id, value: category.code, label: category.name, main_category: category.main_category };
        groupedOptions.find(group => group.label === t(`Category.${category.main_category}`)).options.push(option);
      });

    return groupedOptions;
  };

  const onCategoryChange = (el) => {
    setMainCategory(el.main_category);
    setTimeOffCategory(el.value);
    i18next.exists("CategoryNote." + el.value) ? setFormNote(`${t("CategoryNote." + el.value)} ${i18next.exists("CategoryNote." + el.value + "_url") && t("CategoryNote." + el.value + "_url")}`) : setFormNote("");
  }

  function getDatesBetween(startDate, endDate) {
    const dates = [];
    let currentDate = new Date(startDate);
  
    while (currentDate <= new Date(endDate)) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
  
    return dates;
  }

  function updateHighlightWithRanges(absenceDays) {
    let sickAbsences = [new Date(Date.now() + 2 * 24 * 60 * 60 * 1000)];
    let timeOffAbsences = [];
    let specialAbsences = [];
    let publicHoliday = [];
    let otherAbsences = [];
    absenceDays?.forEach(absence => {
      if (absence.state !== "validate") return;
      const days = getDatesBetween(absence.date_from, absence.date_to); 
      if (absence.time_off_main_category === "sick_leave"){
        sickAbsences = sickAbsences.concat(days);
      } else if (absence.time_off_main_category === "time_off") {
        timeOffAbsences = timeOffAbsences.concat(days);
      } else if (absence.time_off_main_category === "special_leave") {
        specialAbsences = specialAbsences.concat(days);
      } else if (absence.time_off_main_category === "public_holiday") {
        publicHoliday = publicHoliday.concat(days);
      } else {
        otherAbsences = otherAbsences.concat(days);
      }
    })
    setHighlightWithRanges([
      {
        "react-datepicker__day--highlighted-public-holiday": publicHoliday
      },
      {
        "react-datepicker__day--highlighted-sick":  sickAbsences
      },
      {
        "react-datepicker__day--highlighted-time-off": timeOffAbsences
      },
      {
        "react-datepicker__day--highlighted-special": specialAbsences
      },
      {
        "react-datepicker__day--highlighted-uncategorized": otherAbsences
      },
    ])
  }

  function resetForm() {
    setStateRequestForm({ display: false });
    setTimeOffCategory("");
    setFormErrorMsg("");
    setMainCategory("");
    setRequestState(undefined);
    setHighlightWithRanges([]);
    setDateFrom(null);
    setDateTo(null);
    setIsHalfDay(false);
    setDateFromPeriod(undefined);
    setElectronicCertificate(false);
    setFirstDayOfIncapacity(undefined);
    setPaperSickNote(undefined);
    setComment("");
    setFormError(undefined);
    setFormNote(undefined);
  }

  const ModificationHistory = () => {
    const elements = []
    let history = absenceDetails.modification_history;
    history && history.map((el, key) => {
      elements.push(
        <div className="request-form__modification_history_item" key={key}>
          <img alt="" src={(el.state === "To Approve" || el.state === "Zur Genehmigung") ? process.env.PUBLIC_URL + "/images/icon-pending.svg"
            : (el.state === "Approved" || el.state === "Genehmigt") ? process.env.PUBLIC_URL + "/images/icon-approved.svg"
              : (el.state === "Refused" || el.state === "Verworfen") ? process.env.PUBLIC_URL + "/images/icon-refused.svg"
                : ""
          } />
          <div className="d-flex flex-column justify-content-center h-100" style={{ lineHeight: "140%", fontSize: "13px", padding: "0px 15px", gap: "4px" }}>
            {(el.state === "To Approve" || el.state === "Zur Genehmigung") ? t("Message.request_to_approve")
              : (el.state === "Refused" || el.state === "Verworfen") ? `${el.name} ${t("Message.request_refused")}`
                : (el.state === "Approved" || el.state === "Genehmigt") ? (absenceDetails.time_off_main_category === "time_off" ? `${el.name} ${t("Message.request_approved")}` : t("Message.sick_leave_submitted"))
                  : el.state
            }
            <div className="absence-card__write_date">{moment.utc(el.create_date).fromNow()}</div>
          </div>
        </div>
      )
    })
    return elements
  }

  function ModalRefuseDelete() {
    const [refusalComment, setRefusalComment] = useState("");
    const handleClose = () => setShowModalRefuse(false);

    return (
      <Modal show={showModalRefuse} onHide={handleClose}>
        <Form method="post" action="" onSubmit={e => { (requestState === "validate" ? refuseHolidayRequest(e, absenceDetails.id, refusalComment) : deleteHolidayRequest(e, absenceDetails.id)); handleClose() }}>
          <Modal.Body>
            {t("ModalRefuse.confirm_refusal")}
            {requestState === "validate" && <Form.Control as="textarea" className="refusal-comment input" value={refusalComment} onChange={el => setRefusalComment(el.target.value)} placeholder={t("ModalRefuse.comment_placeholder")} required />}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={handleClose}>{t("ModalRefuse.cancel")}</Button>
            <Button type="submit" variant="danger">{t("ModalRefuse.refuse")}</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }

  function renderBubbleState() {
    if (mainCategory !== "time_off") {
        return null; // Return null if the mainCategory is not "time_off"
    }

    // Determine the bubble state class and text based on requestState
    let bubbleClass = "";
    let bubbleText = "";

    switch (requestState) {
        case "validate":
            bubbleClass = "bubble_state--green";
            bubbleText = t("State.approved");
            break;
        case "confirm":
        case "validate1":
            bubbleClass = "bubble_state--orange";
            bubbleText = t("State.pending");
            break;
        case "refuse":
            bubbleClass = "bubble_state--red";
            bubbleText = t("State.refused");
            break;
        default:
            return null; // Return null if the requestState doesn't match any known state
    }

    return (
        <div className={`bubble_state ${bubbleClass}`}>
            {bubbleText}
        </div>
    );
  }

  function FileUploader() {
    const handleFileChange = (e) => {
      if (e.target.files) {
        const file = e.target.files[0];
        const maxSize = 12 * 1024 * 1024; // 12 MB limit
        if (file.size > maxSize){
          alert("The file is too large. Please use a file smaller than 12MB.");
        } else {
          setPaperSickNote(file);
        }
      }
    }

    return (<>
      <label htmlFor="upload-sick-note" className="d-flex align-items-center input" role="button" style={{ gap: "4px" }}>
        <img src={paperSickNote ? process.env.PUBLIC_URL + "/images/icon-file-selected.svg" : process.env.PUBLIC_URL + "/images/icon-select-file.svg"} alt="" />
        {paperSickNote ? paperSickNote.name : <div className="input-placeholder">{t("RequestForm.upload_sick_note")}</div>}
      </label>
      <input type="file" id="upload-sick-note" onChange={handleFileChange} className="d-none" />
    </>);
  }

  const handleRefresh = () => {
    logToDatadog("missing_accessToken: User clicked handleRefresh button on Form.");
    window.location.reload();
  }

  const getMinDate = () => {
    return new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  }

  function renderHalfDayDatePicker() {
    return (
      <>
        <Form.Select
          id="select-date-from-period"
          defaultValue={dateFromPeriod}
          onChange={(el) => setDateFromPeriod(el.target.value)}
          disabled={view === "read"}
        >
          <option value="am">{t("RequestForm.am")}</option>
          <option value="pm">{t("RequestForm.pm")}</option>
        </Form.Select>
        <div className="of">{t("RequestDate.of")}</div>
        {isMobileForm ? (
          <DatePicker
            className="new-date-picker"
            placeholderText={t("RequestDate.single")}
            selected={dateFrom}
            onChange={(date) => setDateFrom(date)}
            selectsStart
            startDate={dateFrom}
            excludeDates={[]}
            dateFormat="EEEE, d. MMMM"
            useWeekdaysShort={true}
            disabled={view === "read"}
            minDate={getMinDate()}
            highlightDates={highlightWithRanges}
            customInput={<InputDatepickerWithoutKeyboardPopUp />}
          />
        ) : (
          <SingleDatePicker
            className="custom-datepicker single-datepicker"
            dateFormat="DD. MMMM"
            monthFormat="MMMM YYYY"
            minDate={moment().subtract(7, "d")}
            startDate={dateFrom || undefined}
            startDatePlaceholder={t("RequestDate.single")}
            onChange={(startDate) => {
              updateDateRange(startDate, startDate);
              closeDatePicker();
            }}
            disabled={view === "read"}
            highlightToday
          />
        )}
      </>
    );
  }

  function renderRangeDatePicker() {
    return (
      <>
        {isMobileForm ? (
          <div className="date-picker-container">
            <DatePicker
              className="new-date-picker"
              placeholderText={t("RequestDate.start")}
              selected={dateFrom}
              onChange={(date) => setDateFrom(date)}
              selectsStart
              startDate={dateFrom}
              endDate={dateTo}
              excludeDates={[]}
              dateFormat="EEEE, d. MMMM"
              useWeekdaysShort={true}
              disabled={view === "read"}
              minDate={getMinDate()}
              highlightDates={highlightWithRanges}
              customInput={<InputDatepickerWithoutKeyboardPopUp />}
            />
            <div className="date-range-separator">
              {t("RequestDate.to_the")}
            </div>
            <DatePicker
              className="new-date-picker"
              placeholderText={t("RequestDate.end")}
              selected={dateTo}
              onChange={(date) => setDateTo(date)}
              selectsEnd
              startDate={dateFrom}
              endDate={dateTo}
              minDate={dateFrom || getMinDate()}
              excludeDates={[]}
              dateFormat="EEEE, d. MMMM"
              useWeekdaysShort={true}
              disabled={view === "read"}
              highlightDates={highlightWithRanges}
              customInput={<InputDatepickerWithoutKeyboardPopUp />}
            />
          </div>
        ) : (
          <>
            <div className="to">{t("RequestDate.to_the")}</div>
            <RangeDatePicker
              className="custom-datepicker range-datepicker"
              dateFormat="DD. MMMM"
              monthFormat="MMMM YYYY"
              startDate={dateFrom}
              endDate={dateTo}
              minDate={absenceDetails ? absenceDetails.date_from : moment().subtract(7, 'd')}
              startDatePlaceholder={t("RequestDate.start")}
              endDatePlaceholder={t("RequestDate.end")}
              onChange={(startDate, endDate) => {
                updateDateRange(startDate, endDate);
                closeDatePicker();
              }}
              disabled={view === "read"}
              highlightToday
            />
          </>
        )}
      </>
    );
  }

  function renderSingleDatePicker() {
    return (
      <>
        {isMobileForm ? (
          <div className="date-picker-container">
            <DatePicker
              className="new-date-picker"
              placeholderText={t("RequestForm.first_day_of_incapacity")}
              selected={firstDayOfIncapacity}
              onChange={(date) => setFirstDayOfIncapacity(date)}
              selectsStart
              startDate={firstDayOfIncapacity}
              excludeDates={[]}
              dateFormat="EEEE, d. MMMM"
              useWeekdaysShort={true}
              minDate={dateFrom || undefined}
              maxDate={dateTo || undefined}
              highlightDates={highlightWithRanges}
              customInput={<InputDatepickerWithoutKeyboardPopUp />}
            />
          </div>
        ) : (
          <>
            <SingleDatePicker
              className="custom-datepicker w-100 single-datepicker"
              dateFormat="DD. MMMM"
              minDate={dateFrom || undefined}
              maxDate={dateTo || undefined}
              startDate={firstDayOfIncapacity}
              startDatePlaceholder={t("RequestForm.first_day_of_incapacity")}
              onChange={(date) => setFirstDayOfIncapacity(date)}
              highlightToday />
          </>
        )}
      </>
    );
  }


  return (
    <div id="view-request-form" className={stateRequestForm.display ? "show" : "hide"}>
      <div className="request-form-component d-flex flex-column align-items-center bg-feed view box position-relative">
        <div className="page-container__divider d-xl-none" />
        <div className="row-holiday-stats d-flex flex-row justify-content-center">
          {Object.keys(statsCurrentYear).length > 0 && mainCategory && mainCategory === "time_off" && <>
            <div className="row-holiday-stats__single">{t("Stats.used")}<div className="row-holiday-stats__single-bubble_state">{statsCurrentYear.used}</div></div>
            <div className="row-holiday-stats__single">{t("Stats.available")}<div className="row-holiday-stats__single-bubble_state">{statsCurrentYear.available}</div></div>
            <div className="row-holiday-stats__single">{t("Stats.pending")}<div className="row-holiday-stats__single-bubble_state">{statsCurrentYear.pending}</div></div>
          </>}
        </div>
        <div className="request-form-wrapper">
          <Form method="post" action="" className={`request-form ` + (mainCategory === "time_off" && ` ${requestState}`)} onSubmit={submitForm}>
            <img
              src={process.env.PUBLIC_URL + "/images/icon-close-form.svg"}
              alt=""
              className="request-form__cross position-absolute"
              onClick={resetForm} />
            {/* Category selection */}
            <Select
              options={getCategories()}
              className="react-select w-100 p-0 ps-1"
              value={valuesSelectedCategory ? { value: valuesSelectedCategory.code, label: valuesSelectedCategory.name } : ""}
              onChange={el => onCategoryChange(el)}
              isDisabled={Object.keys(absenceCategories).length === 0 || (view === "read" || view === "edit")}
              placeholder={t("RequestForm.placeholder_category_select")}
              autoFocus
              required
              components={{ Input: inputSelectWithoutKeyBoardPopUp }}
            />
            <Form.Group className="request-form__group_switch_and_bubble">
              {/* Half-day Switcher */}
              <Form.Check
                type="switch"
                id="switch_half_day"
                label={t("RequestForm.half_day")}
                checked={absenceDetails?.request_unit_half || isHalfDay} onChange={el => { setIsHalfDay(el.target.checked); updateDateRange(dateFrom, dateFrom); }}
                disabled={view === "read" || (absenceDetails && absenceDetails.time_off_main_category === "sick_leave")}
              />
              {/* Shortcut Overview for Allocations remaining when requesting for Paid Time Off */}
              {view === "read" && (
              <div className="request-form__bubble_wrapper">
                <div className="bubble_duration" style={{ opacity: 0.4 }}>
                  {absenceDetails && absenceDetails.number_of_days_display}
                </div>
                {renderBubbleState()}
              </div>)}
            </Form.Group>

            <Form.Group className="d-flex flex-column w-100">
              <div className="request-form__box-datepicker request-form__box-datepicker--range">
                {isHalfDay ? renderHalfDayDatePicker() : renderRangeDatePicker()}
              </div>
            </Form.Group>

            <Form.Control as="textarea" className="request-form__comment input" value={comment} onChange={el => setComment(el.target.value)} placeholder={t("RequestForm.add_comment")} disabled={view === "read"} />
            {mainCategory === "sick_leave" &&
              <Form.Group className="request-form__group_sick-note">
                {valuesSelectedCategory?.electronic_sick_note_possible && <Form.Check type="switch" id="switch_e_au" className="w-100" label={t("RequestForm.electronic_certificate")} checked={electronicCertificate} onChange={el => setElectronicCertificate(el.target.checked)} disabled={view === "read" || absenceDetails?.sick_note_type === "electronic_sick_note"} />}
                {(electronicCertificate || (absenceDetails && absenceDetails.sick_note_type === "electronic_sick_note")) ?
                  <div className="request-form__box-datepicker">
                    {renderSingleDatePicker()}
                  </div>
                  : valuesSelectedCategory?.sick_note_can_be_attached && !electronicCertificate && view !== "read" &&
                  <FileUploader />
                }
              </Form.Group>
            }
            {formError && formError !== "missing_accessToken" && <Alert variant="danger" className="m-0 w-100">{formError}</Alert>}
            {formError && formError === "missing_accessToken" &&
              <Alert variant="info" className="m-0 w-100" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                {t("Alert.refresh_page")}
                <br />
                <br />
                <button className="btn-secondary" onClick={handleRefresh} style={{height: 30}}>
                  <span>Refresh</span>
                </button>
              </Alert>
              }
            {formNote && <Alert variant="primary" className="m-0 w-100" onClose={() => setFormNote("")} dismissible>{formNote}</Alert>}
            {view === "create" &&
              <Button type="submit" className="btn-request w-100">
                {isLoadingRequest && <Spinner size="md" />}
                {t("Button.submit")}
              </Button>
            }
            {view === "edit" && ((mainCategory === "time_off" && requestState === "confirm") || (valuesSelectedCategory?.allow_updating_validate_leave && requestState === "validate" && getDaysBetweenDateAndToday(dateTo) >= -7)) &&
              <Button className="btn-request w-100" onClick={e => updateRequest(e, absenceDetails.time_off_main_category)}>
                {isLoadingRequest && <Spinner size="md" />}
                {t("Button.update")}
              </Button>
            }
            {view === "read" && valuesSelectedCategory?.allow_leave_refusal && requestState === "validate" && getDaysBetweenDateAndToday(dateFrom) >= 7 &&
              <Button className="btn-request btn-request--cancel w-100" onClick={() => setShowModalRefuse(true)}>{isLoadingRequest && <Spinner size="md" />}{t("Button.cancel")}</Button>
            }
            {view === "read" && mainCategory === "time_off" && requestState === "confirm" &&
              <Button className="btn-request btn-request--cancel w-100" onClick={() => setShowModalRefuse(true)}>{isLoadingRequest && <Spinner size="md" />}{t("Button.delete")}</Button>
            }
            {view === "read" && ((mainCategory === "time_off" && requestState === "confirm") || (valuesSelectedCategory?.allow_updating_validate_leave && requestState === "validate" && getDaysBetweenDateAndToday(dateTo) >= -7)) &&
              <div className="request-form__edit-button-box edit-button-box d-flex justify-content-end w-100 position-relative">
                <button type="button" className="btn btn-edit" onClick={() => { getAbsenceDetails(absenceDetails?.id); setStateRequestForm({ display: true, view: "edit", id: stateRequestForm.id }) }}>
                  {t("Button.edit")} <img className="edit-button-box__icon" src={process.env.PUBLIC_URL + "/images/icon-edit.svg"} alt="" />
                </button>
                <img src={process.env.PUBLIC_URL + "/images/icon-bg-labora.svg"} alt="" className="edit-button-box__bg" />
              </div>
            }
          </Form>
          {view === "read" && absenceDetails && absenceDetails.modification_history && absenceDetails.modification_history.length > 0 &&
            <div className="request-form__modification_history">
              <ModificationHistory />
            </div>
          }
        </div>
        <ModalRefuseDelete />
      </div>
    </div>
  )
}

export default RequestForm